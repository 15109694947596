<template>
  <validation-observer rules="required" v-slot="{ handleSubmit }">
    <div class="w-full h-full pt-5 pb-2 flex flex-col justify-between">
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <back-button label="Back" @onClick="$router.back()" variant="secondary" />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-3">
            Compensation
          </h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template v-if="isLoading">
        <div class="w-full mt-60">
          <loader size="xxs" :loader-image="false" />
        </div>
      </template>
      <template v-else>
        <template>
          <div class="flex flex-col justify-between items-start gap-5 mx-5 my-10">
            <div class="w-full flex justify-between items-center">
              <h3 class="font-bold text-lg leading-5 text-darkPurple">
                Start New
              </h3>
            </div>
            <p class="bg-ghostWhite text-darkPurple p-3 rounded-md">
              You have decided to start a new Compensation Review process. Kindly key in the data below and save. Saving this action is not final, the system will benchamark all employees based on the data you have entered to generate a detailed compensation review data for your organisation.
              You may wish to review the generated report with different groups to identify outliers and adjust accordingly.
              Once the group review processes are completed, you can schedule your Compensation Review for approval, if required. You will be required to submit the changes after approval to update employee records.
            </p>
            <template>
              <div class="flex self-end" @click="$refs.create_timelines.toggle(payload)">
                <div class="flex items-center self-end gap-2 cursor-pointer">
                  <Icon icon-name="icon-plus" class-name="text-flame" size="xs" />
                  <p class="font-semibold text-base leading-5 text-flame">Add Timelines</p>
                </div>
              </div>
              <CreateReviewTimelines ref="create_timelines" @submit="payload.timelines = $event" />
            </template>
          </div>
        </template>
        <template>
          <div class="flex flex-col mx-5">
            <div class="flex flex-col justify-start items-center w-full gap-4">
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="text-darkPurple flex flex-col gap-2">
                  <p class="font-normal text-sm">Financial Year</p>
                  <div class="border border-romanSilver rounded-md h-10">
                    <datepicker
                      placeholder="Financial Year"
                      input-class="date-input"
                      style="width:100%"
                      minimum-view="year"
                      maximum-view="year"
                      format="yyyy"
                      v-model="payload.financialYear"
                      :rules="['required']"
                    />
                  </div>
                </div>
                <div class="text-darkPurple flex flex-col justify-start">
                  <p class="font-normal text-sm">Review Type</p>
                  <c-select
                    class="text-sm font-normal"
                    :options="reviewTypeOptions"
                    v-model="payload.reviewTypeId"
                    @input="onSelectReviewType"
                  />
                </div>
                <div class="leading-5 text-darkPurple flex flex-col justify-start">
                  <p class="font-normal text-sm">Review Cycle</p>
                  <c-select
                    class="text-sm font-normal"
                    :options="reviewCycleOptions"
                    v-model="payload.reviewCycleId"
                    :rules="['required']"
                    @input="onSelectReviewCycles"
                  />
                </div>
              </div>
              <div class="flex flex-row justify-center items-center gap-4 w-full">
                <div class="w-full text-darkPurple flex flex-col gap-1 justify-start">
                  <p class="font-normal text-sm">Effective Date</p>
                  <div class="border border-romanSilver rounded-md pl-5 w-full">
                    <div class="flex justify-start items-center gap-2 w-full h-9">
                      <Icon icon-name="calendar" size="xs" />
                      <Divider vertical class="w-5" />
                      <datepicker
                        placeholder="Select Date"
                        input-class="date-input"
                        style="width:100%"
                        v-model="payload.effectiveDate"
                        :rules="['required']"
                        :disabled-dates="disabledDates"
                      />
                    </div>
                  </div>
                </div>
                <c-text
                  label="Revision Range Minimum (%)"
                  placeholder="Min (%)"
                  class="w-full text-sm font-normal"
                  v-model.number="payload.revisionRangeMinimum"
                  :rules="['required']"
                  type="number"
                />
                <c-text
                  label="Revision Range Maximum (%)"
                  placeholder="Max (%)"
                  class="w-full text-sm font-normal"
                  v-model.number="payload.revisionRangeMaximum"
                  :rules="['required']"
                  type="number"
                />
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="relative bottom-0 mx-5 mt-20">
            <div class="flex justify-between items-center">
              <div class="flex justify-start items-center gap-2">
                <c-button
                  label="Save"
                  variant="primary btn-bg button-style"
                  @onClick="handleSubmit(onSubmit)"
                />
                <c-button
                  label="Cancel"
                  variant="bg-transparent button-style"
                  @onClick="$router.back()"
                />
              </div>
              <c-button
                :label="reviewId ? 'Update as Draft' : 'Save as Draft'"
                variant="secondary button-style btn-border"
                @onClick="handleSubmit(onSubmit('draft'))"
              />
            </div>
          </div>
        </template>
      </template>
    </div>
  </validation-observer>
</template>

<script>
  import { format } from "date-fns"
  import Datepicker from "vuejs-datepicker"
  import { ValidationObserver } from "vee-validate"
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
  import CSelect from "@scelloo/cloudenly-ui/src/components/select"
  import CText from "@scelloo/cloudenly-ui/src/components/text"
  import CButton from "@scelloo/cloudenly-ui/src/components/button"

  export default {
    name: 'CreateCompensationReview',
    components: {
      ValidationObserver,
      Datepicker,
      Breadcrumb,
      BackButton,
      CSelect,
      CText,
      CButton,
      Icon: () => import("@/components/Icon"),
      Divider: () => import("@/components/divider"),
      CreateReviewTimelines: () => import('../Modals/CreateReviewTimelines'),
    },
    data(){
      return {
        isViewOnly: false,
        isLoading: true,
        reviews: [],
        reviewId: null,
        reviewTypeOptions: [],
        reviewCycleOptions: [],
        activeReviewCycle: {},
        isSetBandInterval: false,
        disabledDates: {},
        payload: {
          financialYear: '',
          reviewTypeId: '',
          reviewCycleId: '',
          effectiveDate: '',
          revisionRangeMinimum: '',
          revisionRangeMaximum: '',
          timelines: [],
          performanceScores: [],
        },
        breadcrumbs: [
          { disabled: false, text: "Compensation", href: "compensation", id: "Compensation" },
          { disabled: false, text: "Compensation Review", href: "compensationReview", id: "CompensationReview" },
          { disabled: false, text: "Start New", href: "NewReview", id: "NewReview" }
        ],
      }
    },
    methods: {
      onSelectReviewCycles(reviewCycleId){
        const reviewCycle = this.reviewCycleOptions.find(
          review => review.id === reviewCycleId
        )
        this.payload.effectiveDate = new Date(reviewCycle?.startDate)
        this.disabledDates = {
          to: new Date(reviewCycle?.startDate),
          from: new Date(reviewCycle?.endDate)
        }
      },
      onSelectReviewType(payloadId){
        const reviewCycles = this.reviewTypeOptions.find(
          type => type.id === payloadId
        )
        this.reviewCycleOptions = reviewCycles?.cycles?.map(
          cycle => ({ ...cycle, name: cycle.cycleName })
        )
      },
      onSubmit(status){
        this.isLoading = true

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          reviewId: this.reviewId,
          type: this.payload.reviewTypeId,
          status: status || null,
          initiatedBy: this.$AuthUser.id,
          effectiveDate: format(new Date(this.payload.effectiveDate),"yyyy-MM-dd"),
          financialYear: format(new Date(this.payload.financialYear),"yyyy"),
        }

        return this.$_createOrgCompensationReview(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.isLoading = false
          this.$router.back()
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
      async getOrgReviewCycles(){
        await this.$_getOrgReviewCycles().then(({ data }) => {
          this.reviewTypeOptions = data.data?.map((item) => ({
            ...item, name: item.reviewCycle, value: item.id
          }))
        })
      },
      async findCompensationReviewById(id){
        await this.$_findCompensationReviewById(id).then(({ data }) => {
          const foundReviewType = this.reviewTypeOptions.find(({ cycles }) => (
            cycles.some((cycle) => cycle.id === data.data.reviewCycleId)
          ))
          this.reviewCycleOptions = foundReviewType.cycles.map((cycle => ({
            ...cycle, name: cycle.cycleName, value: cycle.id
          })))
          this.payload = {
            ...data.data,
            financialYear: data.data.year,
            reviewId: data.data.reviewId,
            reviewCycleId: data.data.reviewCycleId,
            reviewTypeId: foundReviewType.id
          }
        })
      },
      async bootstrapModule(){
        this.reviewId = this.$route.params.id
        await this.getOrgReviewCycles()
        if(this.reviewId)  await this.findCompensationReviewById(this.reviewId)
      }
    },
    async created(){
      this.isLoading = true
      await this.bootstrapModule()
      this.isLoading = false
    },
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .date-picker {
   height: 37px;
   padding: 0px !important;
   width: 100%;
   border-radius: 10px;
   outline: 0;
 }
 .button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 50px !important;
 }
 .input-disabled-class {
   background: #EEEEEE;
   color: #878E99;
   border: 1px solid #EEEEEE;
 }
 .vdp-datepicker__calendar {
  bottom: 0px !important;
 }
 .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

 .btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
